<template>
  <section id="tokenomics">
    <div class="container text-center">
      <h2>{{ t("tokenomics.header") }}</h2>

      <table>
        <tr>
          <th><h4>{{ t("tokenomics.max_supply") }}</h4></th>
          <th><h4>{{ t("tokenomics.symbol") }}</h4></th>
          <th><h4>{{ t("tokenomics.tax") }}</h4></th>
          <th><h4>{{ t("tokenomics.lp_burnt") }}</h4></th>
        </tr>
        <tr>
          <td>1,000,000,000</td>
          <td>$Bretta</td>
          <td>0%</td>
          <td>{{ t("tokenomics.lp_burnt.value") }}</td>
        </tr>
      </table>

      <h4>{{ t("tokenomics.token_address") }}</h4>
      <input type="text" id="token" v-on:focus="$event.target.select()" value="DXBYAw9aQheMdujaLZYnVSpKSK4n8jMS7HfLbiv5RWnS" @click="copy()" readonly/>
      <small class="copied">{{ t("tokenomics.copied") }}</small>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Tokenomics',
  data() {
    return {
      copiedTimeout: null
    }
  },
  methods: {
    copy() {
      document.querySelector('#token').focus();
      document.execCommand('copy');
      document.querySelector('#token').blur();
      document.querySelector('.copied').classList.add('show');
      clearTimeout(this.copiedTimeout);
      this.copiedTimeout = setTimeout(() => {
        document.querySelector('.copied').classList.remove('show');
      }, 2000);
    }
  }
}
</script>

<style scoped>
table {
  width: 100%;
  max-width: 1000px;
  margin: 5rem auto 3.5rem;
}
table th {
  width: 25%;
}
input {
  width: 100%;
  max-width: 1000px;
  padding: 1.5rem;
  font-size: 1.25rem;
  text-align: center;
  color: #f0f0f0;
  border: 2px solid #000000;
  background: #00000022;
  border-radius: 500px;
  cursor: pointer;
  margin-bottom: 0.5rem;
}
.copied {
  display: block;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.2s;
  user-select: none;
}
.copied.show {
  opacity: 1;
}
</style>