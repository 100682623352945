<template>
  <section id="about">
    <div class="container text-center">
      <h2>{{ t('about.header') }}</h2>

      <p>{{ t('about.paragraph1') }}</p>
      <p>{{ t('about.paragraph2') }}</p>
      <p>{{ t('about.paragraph3') }}</p>

      <div class="video-container">
        <div class="video-background-0"></div>
        <div class="video-background-1"></div>
        <div class="video-background-2"></div>
        <video src="../assets/this-is-bretta.mp4" muted autoplay loop/>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'About',
}
</script>

<style scoped>
section {
  margin-top: -100px;
}
.video-container {
  position: relative;
  width: 80%;
  margin: 2rem auto;
  transform: rotate(-1deg);
}
.video-background-0 {
  position: absolute;
  left: -10px;
  top: calc(5.5% - 10px);
  right: -10px;
  bottom: calc(6.5% - 10px);
  background: rgb(238,238,238);
  background: linear-gradient(180deg, rgb(206, 205, 205) 0%, rgb(150, 148, 148) 100%);
  transform: rotate(3deg) scale(0.98);
}
.video-background-1 {
  position: absolute;
  left: -10px;
  top: calc(5.5% - 10px);
  right: -10px;
  bottom: calc(6.5% - 10px);
  background: rgb(238,238,238);
  background: linear-gradient(180deg, rgb(222, 222, 222) 0%, rgb(183, 182, 182) 100%);
  transform: rotate(1.5deg) scale(0.99);
}
.video-background-2 {
  position: absolute;
  left: -10px;
  top: calc(5.5% - 10px);
  right: -10px;
  bottom: calc(6.5% - 10px);
  background: #ffffff;
}
video {
  width: 100%;
  clip-path: polygon(0 5.5%, 100% 5.5%, 100% 94.2%, 0 94.2%);
}
</style>