<template>
  <section id="roadmap">
    <div class="container text-center">
      <h2>{{ t('roadmap.header')}}</h2>

      <div class="roadmap-phases-container">
        <div>
          <h3>{{ t('roadmap.phase1.header') }}</h3>
          <h5>{{ t('roadmap.phase1.subtitle') }}</h5>
          <ul>
            <li class="checked">{{ t('roadmap.phase1.entry1') }}</li>
            <li class="checked">{{ t('roadmap.phase1.entry2') }}</li>
            <li class="checked">{{ t('roadmap.phase1.entry3') }}</li>
            <li class="checked">{{ t('roadmap.phase1.entry4') }}</li>
            <li>{{ t('roadmap.phase1.entry5') }}</li>
            <li>{{ t('roadmap.phase1.entry6') }}</li>
          </ul>
        </div>
        <div>
          <h3>{{ t('roadmap.phase2.header') }}</h3>
          <h5>{{ t('roadmap.phase2.subtitle') }}</h5>
          <ul>
            <li>{{ t('roadmap.phase2.entry1') }}</li>
            <li>{{ t('roadmap.phase2.entry2') }}</li>
            <li>{{ t('roadmap.phase2.entry3') }}</li>
            <li>{{ t('roadmap.phase2.entry4') }}</li>
            <li>{{ t('roadmap.phase2.entry5') }}</li>
            <li>{{ t('roadmap.phase2.entry6') }}</li>
            <li>{{ t('roadmap.phase2.entry7') }}</li>
            <li>{{ t('roadmap.phase2.entry8') }}</li>
          </ul>
        </div>
        <div>
          <h3>{{ t('roadmap.phase3.header') }}</h3>
          <h5>{{ t('roadmap.phase3.subtitle') }}</h5>
          <ul>
            <li>{{ t('roadmap.phase3.entry1' )}}</li>
            <li>{{ t('roadmap.phase3.entry2' )}}</li>
            <li>{{ t('roadmap.phase3.entry3' )}}</li>
            <li>{{ t('roadmap.phase3.entry4' )}}</li>
            <li>{{ t('roadmap.phase3.entry5' )}}</li>
            <li>{{ t('roadmap.phase3.entry6' )}}</li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Roadmap',
}
</script>

<style scoped>
.roadmap-phases-container {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
}
ul {
  margin-left: 0.5rem;
  margin-top: 1.5rem;
  text-align: left;
  border-left: 2px solid #0a4b6040;
}
ul li {
  padding: 1.1rem 1.5rem;
  margin-right: 1rem;
  position: relative;
  font-size: 1.1rem;
  color: #f0f0f0;
}
ul li.checked {
  font-weight: 600;
  color: #ffffff;
}
ul li::before {
  content: "";
  position: absolute;
  top: 1.18rem;
  left: -10px;
  width: 18px;
  height: 18px;
  background: #427b91;
  background: linear-gradient(180deg, #0a4b60 0%, #427b91 100%);
  border-radius: 500px;
  box-shadow: 0 0 10px #0a4b60aa;
}
ul li.checked::before {
  background: #00DA57FF;
  background: linear-gradient(180deg, rgb(17, 252, 0) 0%, rgb(4, 182, 75) 100%);
}
h4 {
  font-weight: 400;
}
h5 {
  height: 46px;
}
@media (max-width: 800px) {
  h3 {
    font-size: 2rem;
  }
  h5 {
    font-family: 'Albert Sans', sans-serif;
    font-size: 1rem;
  }
  ul {
    margin-left: 0.25rem;
    margin-top: 1.5rem;
    text-align: left;
    border-left: 2px solid #0a4b6040;
  }
  ul li {
    padding: 1rem 1.25rem 1rem 1.1rem;
    margin-right: 0.5rem;
  }
  ul li::before {
    top: 1.3rem;
    left: -7px;
    width: 11px;
    height: 11px;
  }
}
</style>