<template>
  <footer id="footer">
    <div class="container text-center">
      {{ t('footer.copyright') }} <span class="copyright">©</span> 2024. {{ t('footer.all_rights_reserved') }}
    </div>
  </footer>
</template>

<script>
export default {
  name: 'Footer',
}
</script>

<style scoped>
footer {
  font-family: 'Sriracha', sans-serif;
  border-top: 4px solid #000;
  background: #174495;
  background: linear-gradient(141deg, #174495 0%, rgb(5, 111, 187) 100%);
  padding: 2.5rem;
  text-transform: uppercase;
  font-size: 1.25rem;
}
.copyright {
  font-family: 'Arial', sans-serif;
  font-size: 1.25rem;
  font-weight: bold;
}
</style>
