<template>
  <header id="header">
    <div class="container">
      <nav>
        <ul>
          <li><a href="#about">{{ t('header.menu.about_us') }}</a></li>
          <li><a href="#tokenomics">{{ t('header.menu.tokenomics') }}</a></li>
          <li><a href="#roadmap">{{ t('header.menu.roadmap') }}</a></li>
          <li><a href="#swap">{{ t('header.menu.swap') }}</a></li>
          <li><a href="#gallery">{{ t('header.menu.gallery') }}</a></li>
          <li><a href="/bretta-white-paper.pdf" target="_blank">{{ t('header.menu.whitepaper') }}</a></li>
          <li class="coming-soon hidden-xs"><a href="#roadmap">{{ t('header.menu.merch') }}</a></li>
          <li class="coming-soon hidden-xs"><a href="#roadmap">{{ t('header.menu.nft_collection') }}</a></li>
        </ul>
      </nav>
    </div>
  </header>
</template>

<script>
export default {
  name: 'Header',
}
</script>

<style scoped>
header {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  align-items: center;
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
  z-index: 51;
  background: transparent;
  background: linear-gradient(180deg, rgba(0,184,238,1) 50%, rgba(0,184,238,0) 100%);
}
header .container {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
header nav {
  width: 100%;
}
header nav ul {
  display: flex;
  justify-content: space-around;
}
header nav ul li a {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background: url("../assets/header-cloud.png");
  width: 143px;
  height: 77px;
  color: #4b5459;
  text-align: center;
  transition: all 0.2s;
  text-shadow: 0 0 transparent;
  font-size: 1.25rem;
  font-family: 'Sriracha', sans-serif;
  font-weight: 400;
}
header nav ul li a:hover {
  transform: scale(1.1);
}
.coming-soon:hover::after {
  content: "Coming soon, check roadmap";
  position: absolute;
  margin-left: -52px;
  background: rgba(10, 75, 96, 0.5);
  padding: 0.5rem 1rem;
  border-radius: 50px;
  font-size: 1rem;
}
@media (max-width: 1200px) {
  header nav ul li a {
    background: transparent;
    color: #ffffff;
    width: auto;
    height: 40px;
    padding: 0 1rem 0 0;
  }
  header nav ul li:nth-child(6) a {
    background: transparent;
    color: #ffffff;
    width: auto;
    height: 40px;
    padding: 0;
  }
}
</style>
