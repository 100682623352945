import locale_pl from './locales/pl.json';
import locale_en from './locales/en.json';
import locale_in from './locales/in.json';
import locale_ae from './locales/ae.json';
import locale_pt from './locales/pt.json';
import locale_nl from './locales/nl.json';
import locale_de from './locales/de.json';
import locale_es from './locales/es.json';
import locale_it from './locales/it.json';
import locale_fr from './locales/fr.json';
import locale_ru from './locales/ru.json';
import locale_ua from './locales/ua.json';
import locale_tr from './locales/tr.json';
import locale_cn from './locales/cn.json';
import locale_jp from './locales/jp.json';

export default {
    data() {
        return {
            translations: {
                pl: locale_pl,
                en: locale_en,
                in: locale_in,
                ae: locale_ae,
                pt: locale_pt,
                nl: locale_nl,
                de: locale_de,
                es: locale_es,
                it: locale_it,
                fr: locale_fr,
                ru: locale_ru,
                ua: locale_ua,
                tr: locale_tr,
                cn: locale_cn,
                jp: locale_jp,
            }
        }
    },
    methods: {
        t: function(key) {
            return this.translations[this.$root.lang][key];
        }
    }
}
