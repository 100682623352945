<template>
  <Intro :key="lang"/>
  <Header :key="lang"/>
  <Banner :key="lang"/>
  <About :key="lang"/>
  <Tokenomics :key="lang"/>
  <Roadmap :key="lang"/>
  <Swap :key="lang"/>
  <Gallery :key="lang"/>
  <Socials :key="lang"/>
  <Footer :key="lang"/>

  <button @click="toggleMute()" class="mute">
    <img v-if="!muted" src="./assets/speaker-unmuted.webp"/>
    <img v-if="muted" src="./assets/speaker-muted.webp"/>
  </button>
</template>

<script>
import Intro from "@/components/Intro.vue";
import Header from "@/components/Header.vue";
import Banner from "@/components/Banner.vue";
import About from "@/components/About.vue";
import Tokenomics from "@/components/Tokenomics.vue";
import Roadmap from "@/components/Roadmap.vue";
import Swap from "@/components/Swap.vue";
import Gallery from "@/components/Gallery.vue";
import Socials from "@/components/Socials.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {Intro, Header, Banner, About, Tokenomics, Roadmap, Swap, Gallery, Socials, Footer},
  data() {
    return {
      lang: 'en',
      audio: null,
      muted: false,
    }
  },
  methods: {
    toggleMute() {
      this.muted = !this.muted;
      if (this.muted) {
        this.audio.pause();
      } else {
        this.audio.load();
        this.audio.play();
      }
    },
  },
  mounted() {
    this.audio = new Audio('/bretta-bgm.mp3');
  }
}
</script>

<style>
@import url('https://fonts.cdnfonts.com/css/albert-sans');
@import url('https://fonts.cdnfonts.com/css/radhesty');
@import url('https://fonts.cdnfonts.com/css/sriracha');

*, ::after, ::before {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

body {
  font-family: 'Albert Sans', sans-serif;
  font-size: 1.25rem;
  text-shadow: 0 1px 5px rgba(10, 75, 96, 0.80);
  color: #f6f6f9;
  background: #16C0FA;
  background: linear-gradient(141deg, #1ac3fc 0%, #009ece 100%);
  overflow: hidden;
}

body.initialised {
  overflow: auto;
}

#app {
  background: url("assets/repeating-backdrop.png") fixed;
  background-size: 100%;
}

ul {
  list-style-type: none;
}

a {
  text-decoration: none;
  color: #ffffff;
}

.container {
  width: 65vw;
}

.flex {
  display: flex;
}

.justify-center {
  justify-content: center;
}

.text-center {
  text-align: center;
}

header, section, footer {
  display: flex;
  justify-content: center;
}

section {
  padding: 150px 0 50px;
}

h2 {
  font-family: 'Radhesty', sans-serif;
  font-size: 4rem;
}

h3 {
  font-family: 'Sriracha', sans-serif;
  font-size: 2.5rem;
}

h4 {
  font-family: 'Sriracha', sans-serif;
  font-size: 2rem;
}

h5 {
  font-family: 'Sriracha', sans-serif;
  font-size: 1.25rem;
  font-weight: 500;
}

h2 {
  margin-bottom: 3rem;
}
p {
  margin-bottom: 1.5rem;
}
.mute {
  position: fixed;
  bottom: 1.5rem;
  right: 1.5rem;
  width: 75px;
  height: 75px;
  background: linear-gradient(141deg, #174495 0%, rgb(5, 111, 187) 100%);
  border-radius: 50px;
  border: 3px solid #ffffff;
  box-shadow: 0 0 10px #0a4b60aa;
  cursor: pointer;

  img {
    transform: scale(0.9);
  }
}
@media (max-width: 1200px) {
  section {
    padding: 100px 0 50px;
  }
  .container {
    width: 80vw;
  }
}

@media (max-width: 800px) {
  html {
    font-size: 12px;
  }
  .container {
    width: 90vw;
  }
}

@media (max-width: 800px) {
  .hidden-xs {
    display: none;
  }
}
</style>
