<template>
  <section id="gallery">
    <div>
      <h2 class="text-center">{{ t('gallery.header') }}</h2>

      <div class="scroll-container" v-for="(group, groupIndex) in artworks" :key="groupIndex">
        <div class="scrolling-items" style="width: 8600px; animation-duration: 80s;">
          <div class="scrolling-item">
            <img v-for="(image, imageIndex) in group" :src="image" :key="imageIndex" draggable="false" height="200"/>
          </div>
          <div class="scrolling-item">
            <img v-for="(image, imageIndex) in group" :src="image" :key="imageIndex" draggable="false" height="200"/>
          </div>
        </div>
      </div>

    </div>
  </section>
</template>

<script>
export default {
  name: 'Gallery',
  data() {
    return {
      artworks: [
          [
            '/artworks/010f0b7305fd9ad3089c7e49f717544d.webp',
            '/artworks/2.jpg',
            '/artworks/2a9c69015117c6651862bdde13381b2b.webp',
            '/artworks/3.jpg',
            '/artworks/379c0ddee3953f8dab1ca4d673edb4c2.webp',
            '/artworks/6.jpg',
            '/artworks/3be08b9630398f633c3643e304e7442d.webp',
            '/artworks/7.jpg',
            '/artworks/4461435bcb7785e32faeceb5b900c847.webp',
            '/artworks/8.jpg',
            '/artworks/586d65949fa42346316fd6b279661b04.webp',
            '/artworks/9.jpg',
            '/artworks/8e4f874bd4cfa6126d773a6c41763470.webp',
            '/artworks/11.jpg',
            '/artworks/6c612b1ce8924f75f8af6e0144223537.webp',
            '/artworks/12.jpg',
            '/artworks/6fcb6487fc0fa7ef58a08f0dfb2bc7b8.webp',
            '/artworks/13.jpg',
          ],
          [
            '/artworks/709f8f051b4fe5c143118e90908ed232.jpg',
            '/artworks/14.jpg',
            '/artworks/7b0f3118fdeae66500f52393465ac472.webp',
            '/artworks/16.jpg',
            '/artworks/7c20017b481b8c30042b38bd0319e354.webp',
            '/artworks/23.jpg',
            '/artworks/8158843b154efa7b9023db97c711334f.webp',
            '/artworks/18.jpg',
            '/artworks/875c1fa9f11b4cd990befc8f11bb8bfe.webp',
            '/artworks/19.jpg',
            '/artworks/8823bf748805fd0a1da64602d2e9859c.webp',
            '/artworks/20.jpg',
            '/artworks/6104fa41741f8c03327a0dc5b72da854.webp',
            '/artworks/21.jpg',
            '/artworks/b0f2aa81d7448cbd6e1985efac2f4321.webp',
            '/artworks/22.jpg',
            '/artworks/b3b24e50208d3c7002469d7d2c995017.webp',
            '/artworks/33.jpg',
          ],
          [
            '/artworks/b5e4ab817fb659831de44266576d27b0.webp',
            '/artworks/24.jpg',
            '/artworks/b69a464df8cda2132666d7c79d6bcf51.webp',
            '/artworks/25.jpg',
            '/artworks/cebe5f875510f335099d35e81baa2ecf.webp',
            '/artworks/26.jpg',
            '/artworks/e2de9236a6bca37b711c77d14e30bbf5.webp',
            '/artworks/27.jpg',
            '/artworks/e4ed7c86b70ad371c48c9393b9033030.webp',
            '/artworks/28.jpg',
            '/artworks/e527915b1775cb98b7e2d217a5b21e14.webp',
            '/artworks/29.jpg',
            '/artworks/ed3f5ef46ae3ab0a3dcb1fd91123009d.webp',
            '/artworks/30.jpg',
            '/artworks/fa84c957dc300648322bfdd54bbb1a66.webp',
            '/artworks/31.jpg',
            '/artworks/e527915b1775cb98b7e2d217a5b21e15.png',
            '/artworks/17.jpg',
          ],
      ]
    }
  },
}
</script>

<style scoped>
section {
  overflow-x: clip;
  padding-bottom: 0;
}
@keyframes infiniteScroll {
  from {transform: translateX(0)}
  to {transform: translateX(-50%)}
}
.scroll-container {
  max-width: 100vw;
  overflow-x: clip;
}
.scrolling-items {
  display: flex;
  font-size: 40px;
  animation-name: infiniteScroll;
  animation-iteration-count: infinite;
  animation-timing-function: linear;
}
.scrolling-item {
  position: relative;
  white-space: nowrap;
}
.scrolling-item img {
  height: 200px;
  margin: 10px;
  padding: 5px;
  background: linear-gradient(180deg, #fafafa 0%, #eaeaea 100%);
  transition: all 0.15s;
  box-shadow: 0 0 6px #0a4b60;
  opacity: 0.95;
  filter: brightness(0.95);
  user-select: none;
}
.scrolling-item img:hover {
  position: relative;
  transform: scale(1.25);
  z-index: 10;
  opacity: 1;
  filter: brightness(1);
}
</style>