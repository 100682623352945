<template>
  <div id="intro" v-if="!skipped" :class="{clicked: clicked}">
    <div class="image-container">
      <img src="../assets/bretta-intro.png"/>
    </div>
    <div class="form-container">
      <select v-model="$root.lang">
        <option value="en">English</option>
        <option value="pl">Polski</option>
        <option value="in">हिंदी</option>
        <option value="ae">عربي</option>
        <option value="pt">Português</option>
        <option value="nl">Nederlands</option>
        <option value="de">Deutsch</option>
        <option value="es">Español</option>
        <option value="it">Italiano</option>
        <option value="fr">Français</option>
        <option value="ru">Русский</option>
        <option value="ua">українська</option>
        <option value="tr">Türkçe</option>
        <option value="cn">中国人</option>
        <option value="jp">日本語</option>
      </select>
      <button @click="onEnterClick" :class="{clicked: clicked}">{{ t('intro.enter') }}</button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Intro',
  data() {
    return {
      clicked: false,
      skipped: false,
    }
  },
  methods: {
    onEnterClick() {
      document.querySelector('body').classList.add('initialised');
      this.clicked = true;
      this.$root.audio.play();
      setTimeout(() => {
        this.skipped = true;
      }, 200);
    }
  }
}
</script>

<style scoped>
#intro {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  background: rgba(14, 35, 57, 1);
  background: radial-gradient(circle, rgba(15, 57, 84, 1) 0%, rgba(14, 35, 57, 1) 58%, rgba(0, 0, 0, 1) 100%);
  z-index: 100;
  transition: all 0.2s;
}

#intro.clicked {
  transform: scale(5);
  opacity: 0;
}

.image-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: end;
  text-align: center;
  height: 100%;
}

img {
  max-width: 100vw;
  max-height: 100vh;
  opacity: 0.9;
}

.form-container {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  height: 100%;
}

select {
  font-family: 'Sriracha', sans-serif;
  border: 2px outset #000000;
  font-size: 2.5rem;
  border-radius: 10px;
  margin-bottom: 1rem;
  background: rgba(0, 0, 0, 0.6);
  color: #f0f0f0;
  text-shadow: 0 0 10px #0a4b60;
  text-align: center;
  padding: 0.25rem 1rem;
  cursor: pointer !important;
  backdrop-filter: blur(2px);
}

option {
  font-family: 'Albert Sans', sans-serif;
  font-size: 1.2rem;
  background: #f0f0f0;
  color: #000000;
  text-shadow: none;
}

button {
  display: block;
  font-family: 'Sriracha', sans-serif;
  font-size: 2rem;
  color: #ffffff;
  margin: 0.75rem 0.75rem 2rem;
  padding: 0.25rem 2rem;
  text-transform: uppercase;
  border-radius: 500px;
  border: 4px solid #ffffff;
  opacity: 1;
  box-shadow: 0 0 6px #0a4b60;
  background: rgb(252, 0, 139);
  background: linear-gradient(180deg, rgba(252, 0, 139, 1) 0%, rgba(218, 0, 120, 1) 100%);
  transition: all 0.2s;
  cursor: pointer;
  font-weight: 600;
}

button:hover {
  filter: brightness(1.15);
  transform: scale(1.1);
}
</style>
