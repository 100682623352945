<template>
  <section id="swap">
    <div class="container text-center">
      <h2>{{ t('swap.header') }}</h2>

      <div style="display: flex; justify-content: space-between; align-items: center; ">
        <div class="image-container">
          <img src="../assets/swap-image.png"/>
        </div>
        <div class="swap-container">
          <div id="integrated-terminal"></div>
        </div>
      </div>


      <div style="display: flex; justify-content: space-between; align-items: center; ">
        <div style="width: 75%; margin: 2rem auto;">
          <h2 class="how-to-buy-header">{{ t('swap.how_to_buy') }}</h2>

          <p>{{ t('swap.paragraph1') }}</p>

          <a href="https://phantom.app/" target="_blank">
            <img src="https://images.ctfassets.net/23fkqdsgbpuj/5wvgow43SVukSTNtFCJnfZ/33baaae7428aff284164b04bb99703f8/Phantom-logo.png" width="200" style="margin-bottom: 1.5rem;"/>
          </a>

          <p>{{ t('swap.paragraph2') }}</p>

          <a href="https://brettasolana.com/">
            <div class="type-bretta">$Bretta</div>
          </a>

          <p>{{ t('swap.paragraph3') }}</p>
        </div>
        <div class="image-container">
          <img src="../assets/how-to-buy-image.png"/>
        </div>
      </div>


    </div>
  </section>
</template>

<script>
export default {
  name: 'Swap',
  mounted() {
    window.Jupiter.init({
      displayMode: "integrated",
      integratedTargetId: "integrated-terminal",
      endpoint: "https://api.mainnet-beta.solana.com",
      strictTokenList: false,
      formProps: {
        initialInputMint: "So11111111111111111111111111111111111111112",
        fixedOutputMint: true,
        initialOutputMint: "DXBYAw9aQheMdujaLZYnVSpKSK4n8jMS7HfLbiv5RWnS",
      },
    });
  }
}
</script>

<style scoped>
#integrated-terminal {
  display: none;
}
body.initialised #integrated-terminal {
  display: block;
}
h3 {
  margin: 2.5rem 0;
}
.image-container img {
  max-width: 100%;
  transform: scale(0.9);
}
.swap-container {
  min-width: 450px;
  margin: auto;
}
.type-bretta {
  font-family: 'Radhesty', sans-serif;
  font-size: 3rem;
  margin-bottom: 1.25rem;
  text-shadow: 0 0 0 transparent;
}
@media (max-width: 1250px) {
  .image-container {
    display: none;
  }
}
@media (max-width: 800px) {
  .swap-container {
    min-width: 100%;
    width: 100%;
  }
  .how-to-buy-header {
    margin-top: 3rem;
    font-size: 3rem;
  }
}

</style>
