<template>
  <section id="banner">
    <div class="container text-center">
      <img src="../assets/banner.png"/>
      <h4>{{ t('banner.header') }}</h4>
      <div>
        <a class="button" href="https://jup.ag/dca/SOL-DXBYAw9aQheMdujaLZYnVSpKSK4n8jMS7HfLbiv5RWnS" target="_blank">{{ t('banner.buttons.buy_now') }}</a>
        <a class="button" href="https://dexscreener.com/solana/DXBYAw9aQheMdujaLZYnVSpKSK4n8jMS7HfLbiv5RWnS" target="_blank">{{ t('banner.buttons.chart') }}</a>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Banner',
}
</script>

<style scoped>
section {
  height: 100vh;
  align-items: center;
}
img {
  max-width: 100%;
}
h4 {
  font-weight: 400;
  font-size: 2rem;
  margin: 0.5rem 0 1.5rem;
}
.button {
  display: inline-block;
  font-family: 'Sriracha', sans-serif;
  font-size: 1.75rem;
  margin: 0.75rem 0.75rem 2rem;
  padding: 0.5rem 2rem 0.75rem;
  text-transform: uppercase;
  border-radius: 500px;
  border: 4px solid #ffffff;
  box-shadow: 0 0 6px #0a4b60;
  background: rgb(252,0,139);
  background: linear-gradient(180deg, rgba(252,0,139,1) 0%, rgba(218,0,120,1) 100%);
  transition: all 0.2s;
  font-weight: 600;
}
.button:hover {
  filter: brightness(1.15);
}
@media (max-width: 800px) {
  .button {
    border: 3px solid #ffffff;
    font-size: 1.5rem;
    padding: 1rem 2rem 0.8rem;
    margin: 0.75rem 0.75rem 3.5rem;
  }
}
</style>
