<template>
  <section>
    <div class="container text-center">
      <h5><a href="mailto:solbretta@gmail.com">solbretta@gmail.com</a></h5>
      <a href="https://t.me/SolBretta" target="_blank">
        <img src="../assets/telegram.webp"/>
      </a>
      <a href="https://twitter.com/BrettaOnSolana" target="_blank">
        <img src="../assets/x.webp"/>
      </a>
      <a href="https://www.youtube.com/playlist?list=PL-8BLw1RmeNlvfKqao42JHf4YExMhvx2i" target="_blank">
        <img src="../assets/youtube.webp"/>
      </a>
      <a href="https://www.tiktok.com/@brettasolana" target="_blank">
        <img src="../assets/tiktok.webp"/>
      </a>
      <a href="https://www.instagram.com/solbretta/" target="_blank">
        <img src="../assets/instagram.webp"/>
      </a>
    </div>
  </section>
</template>

<script>
export default {
  name: 'Socials'
}
</script>

<style scoped>
section {
  padding-top: 100px;
  padding-bottom: 100px;
}
img {
  width: 75px;
  height: 75px;
  margin: 0.5rem;
}
h5 {
  margin-bottom: 2rem;
}
@media (max-width: 800px) {
  img {
    width: 50px;
    height: 50px;
    margin: 0.5rem;
  }
}
</style>
